import React ,{useState, useEffect, useRef} from 'react'

import { useParams } from "react-router-dom";

import {  BASE_API , BASE_URL } from "../config/constants";

import { creaCodigos, modificaCodigos} from "../controllers/codigos.js";

import { Button , Image } from "@chakra-ui/react";

import VCard from 'vcard-creator'

const VerTarjetaForm = (props) => {
    
    const {codigouuid} = props

    console.log(" codigouuid => ",codigouuid);
    

    const uuid = useParams();

    const [codigo, setCodigo] = useState()
    const [refetch, setRefetch] = useState(false);


    useEffect(()=>{        
    //    console.log(codigo);               
    }, [codigo])  

    useEffect(() => {        
        
        async function fetchData() {
          const u = await getCodigo();
          setCodigo(u);                     
        }
        
        fetchData();
        
      }, [refetch]); // Or [] if effect doesn't need props or state        

      const getCodigo = async()=>{
        try {
            
            const response = await fetch(`${BASE_API}/getcodigo/${uuid.uuid}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },                
            });

            const result = await response.json();

            const sumacuantos = await fetch(`${BASE_API}/sumacuantos`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },                
                body:JSON.stringify({uuid:uuid.uuid})
            });            

            console.log(" respuesta getcodigo => ",result.codigos[0]);            

            return result.codigos[0];            
        
        } catch (e) {
            console.error("ERROR_cargaUsuarios: " + e);
        }          
    }      


    const descargaVCF= async()=>{

        const myVCard = new VCard()

        // Some variables
        const lastname = codigo?.apellidos
        const firstname = codigo?.nombre
        const additional = ''
        const prefix = ''
        const suffix = ''

        myVCard
        // Add personal data
        .addName(lastname, firstname, additional, prefix, suffix)
        // Add work data
        .addCompany(codigo?.empresa)
        .addJobtitle(codigo?.puestoempresa)
        // .addRole('Data Protection Officer')
        .addEmail(codigo?.correoelectronico)
        .addPhoneNumber(codigo?.telefono, 'PREF')
        .addPhoneNumber(codigo?.telefonoempresa, 'WORK')
        // .addAddress(null, null, 'street', 'worktown', null, 'workpostcode', 'Belgium')
        // .addSocial('https://twitter.com/desloovere_j', 'Twitter', 'desloovere_j')
        .addURL(codigo?.sitioweb)

        // console.log(myVCard.toString())

        myVCard.setFormat('vcard')

        const file = new Blob([myVCard.toString()], {
          type: "text/plain;charset=utf-8"
        });

        const a = document.createElement("a");
        a.href = URL.createObjectURL(file);
        a.download = `${codigo.nombre} ${codigo.apellidos}.vcf`;
        a.click();

    }


    return (    
        <div style={containerStyle}>
            {codigo?.foto && (
                <>
                
                <div style={headerStyle}>
                    <img src={codigo?.foto} alt="Foto" style={imageStyle} />
                    <p><strong><h2>{codigo?.nombre} {codigo?.apellidos}</h2></strong></p>
                    {codigo?.activo ? 
                        <>
                            <a 
                                href={`mailto:${codigo.correoelectronico}`} 
                                style={iconLinkStyle}
                            >
                                <Image src='/sobre.png' width="50" height="30" />
                            </a>

                            <a 
                                href={`https://wa.me/${codigo.telefono}`} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                style={iconLinkStyle}
                            >
                                <Image src='/whatsapp.png' width="50" height="30" />
                            </a>
                        </>
                    :''}
                </div>
                
                </>
            )}

        {codigo?.activo ? 
            <>
            { codigo?.telefono || 
                    codigo?.correoelectronico  ||  codigo?.direccion  || 
                    codigo?.poblacion  ||  codigo?.codigopostal  || 
                    codigo?.provincia  ||  codigo?.pais  ||  codigo?.sitioweb ?
                <div style={cardStyle}>
                    <div style={layoutStyle}>
                        <div >   
                            <h2>Información Personal</h2>
                        </div>
                        <div >
                            <a target="_blank" rel="noopener noreferrer" style={iconLinkStyle} onClick={descargaVCF}>
                                <Image src='/vcf.png' width="50" height="30" alt="Añadir a contactos"/>
                                Añadir a contactos
                            </a>
                        </div>
                    </div>
                    { codigo?.nombre ?
                            <p><strong>Nombre:</strong> {codigo?.nombre} {codigo?.apellidos}</p>
                    : ''}                               
                    { codigo?.direccion ?                      
                        <p><strong>Dirección:</strong> {codigo?.direccion}</p>
                    : ''}              
                    { codigo?.poblacion ?                      
                        <p><strong>Población:</strong> {codigo?.poblacion}</p>
                    : ''}              
                    { codigo?.codigopostal ?                      
                        <p><strong>Código Postal:</strong> {codigo?.codigopostal}</p>
                    : ''}              
                    { codigo?.provincia ?                      
                        <p><strong>Provincia:</strong> {codigo?.provincia}</p>
                    : ''}              
                    { codigo?.pais ?                      
                        <p><strong>País:</strong> {codigo?.pais}</p>
                    : ''}     
                    { codigo?.telefono ?
                            <p><strong>Teléfono:</strong> {codigo?.telefono}</p>
                    : ''}              
                    { codigo?.correoelectronico ?      
                            <p><strong>Correo Electrónico:</strong> {codigo?.correoelectronico}</p>
                    : ''}                                
                    { codigo?.sitioweb ?                      
                        <p><strong>Sitio Web:</strong> {codigo?.sitioweb}</p>
                    : ''}                              
                </div>
            : ''}
                    
            { codigo?.empresa || codigo?.puestoempresa || codigo?.telefonoempresa ||
                    codigo?.correoelectronicoempresa || codigo?.direccionempresa ||
                    codigo?.poblacionempresa || codigo?.codigopostalempresa ||
                    codigo?.provinciaempresa || codigo?.paisempresa ?
                <div style={cardStyle}>
                
                    <h2>Información de la Empresa</h2>
                
                    { codigo?.empresa ?                                      
                            <p><strong>Empresa:</strong> {codigo?.empresa}</p>
                    : ''}              
                    { codigo?.puestoempresa ?                                      
                            <p><strong>Puesto:</strong> {codigo?.puestoempresa}</p>
                    : ''}              
                                 
                    { codigo?.direccionempresa ?                                      
                            <p><strong>Dirección:</strong> {codigo?.direccionempresa}</p>
                    : ''}              
                    { codigo?.poblacionempresa ?                                      
                            <p><strong>Población:</strong> {codigo?.poblacionempresa}</p>
                    : ''}              
                    { codigo?.codigopostalempresa ?                                      
                            <p><strong>Código Postal:</strong> {codigo?.codigopostalempresa}</p>
                    : ''}              
                    { codigo?.provinciaempresa ?                                      
                            <p><strong>Provincia:</strong> {codigo?.provinciaempresa}</p>
                    : ''}              
                    { codigo?.paisempresa ?                                      
                            <p><strong>País:</strong> {codigo?.paisempresa}</p>
                    : ''}              
                    { codigo?.telefonoempresa ?                                      
                            <p><strong>Teléfono:</strong> {codigo?.telefonoempresa}</p>
                    : ''}              
                    { codigo?.correoelectronicoempresa ?                                      
                            <p><strong>Correo Electrónico:</strong> {codigo?.correoelectronicoempresa}</p>
                    : ''}                     
                    { codigo?.sitiowebempresa ?                                      
                            <p><strong>Sitio Web:</strong> {codigo?.sitiowebempresa}</p>
                    : ''}              
                </div>
            : ''}              
            </>
        : <>
                <p align='center'><strong><h2>¡¡ Estas intentando ver una tarjeta QR desactivada !! </h2></strong></p>
                <p align='center'><h4>Las funciones y los datos no se pueden ver. </h4></p>
          </>
        }
            {/* <div style={{ ...cardStyle, textAlign: 'center' }}>
                <h2>Código QR</h2>
                <p>Escanea este código para más detalles:</p>
                <img src={codigo?.qr} alt="QR Code" style={{ maxWidth: '150px', marginTop: '10px' }} />
            </div>

            <div style={cardStyle}>
                <h2>Metadatos</h2>
                <p><strong>UUID:</strong> {codigo?.uuid}</p>
                <p><strong>Creado:</strong> {codigo?.createdAt}</p>
                <p><strong>Actualizado:</strong> {codigo?.updatedAt}</p>
            </div> */}
        </div>
    );
};

const containerStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    backgroundColor: '#e6f7ff'
};

const headerStyle = {
    textAlign: 'center',
    backgroundColor: '#005b96',
    color: '#ffffff',
    padding: '20px',
    borderRadius: '8px 8px 0 0'
};

const imageStyle = {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    border: '3px solid #fff'
};

const cardStyle = {
    background: '#fff',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    marginBottom: '20px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
};



const iconLinkStyle = {
    marginLeft: '10px',
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center'
};


const layoutStyle = {
    display: 'flex',
    gap: '20px',
    padding: '20px',
    backgroundColor: '#f0f0f0',
    fontFamily: 'Arial, sans-serif',
};

const leftColumnStyle = {
    flex: '3',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const rightColumnStyle = {
    flex: '1',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};


export default VerTarjetaForm;
