import React from 'react'

function SobreNosotros() {
    return (
        <div>
            <h1>Esta es la página sobre nosotros</h1>
        </div>
    )
}

export default SobreNosotros