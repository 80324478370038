// const { sequelize2 } = require("../config/mysql");
import axios from "axios"

import {  BASE_API } from "../config/constants";

import {toast , Toaster } from 'react-hot-toast';

export async function creaCodigos(obj) {
    // console.log(" creaCodigo => ",obj);
    
    const res = await axios(BASE_API+"/creacodigo", {
            method: "post",
            data: obj,
            // headers: { Authorization: token}
    })
    
    const {status, message} = res.data

    // console.log(status);
    
    if(status === 1){
            toast.success(" Código creado correctamente ")
            // dispatch({type: CREATE_NOTES_SUCCESS})
            // dispatch(getNotes())
    }else if (status === 2){
        // dispatch({type: LOGOUT})
    }else {
        toast.error(' Error al crear el código. '+message)
            // alert(' Error al crear la nota. '+message)
                // dispatch({type: CREATE_NOTES_ERROR})
    }
}


export async function modificaCodigos(id,obj) {
    // console.log(" modificaCodigos => ",obj);
    
    const res = await axios(BASE_API+"/modificacodigo", {
            method: "patch",
            data: obj,
            headers: { id:id}
    })
    
    const {status, message} = res.data

    // console.log(status);
    
    if(status === 1){
            toast.success(" Código modificado correctamente ")
            // dispatch({type: CREATE_NOTES_SUCCESS})
            // dispatch(getNotes())
    }else if (status === 2){
        // dispatch({type: LOGOUT})
    }else {
        toast.error(' Error al modificar el código. '+message)
            // alert(' Error al crear la nota. '+message)
                // dispatch({type: CREATE_NOTES_ERROR})
    }
}

// module.exports = {    creaCodigos};


// import {toast , Toaster } from 'react-hot-toast';

// export const creaCodigos = (obj)=>async()=>{
//     try {
        
//         console.log(" creaCodigo => ",obj);
        
//         // const res = await axios(BASE_URL+"/note/create", {
//         //     method: "post",
//         //     data: obj,
//         //     headers: {
//         //         Authorization: token
//         //     }
//         // })
//         // const {status, message} = res.data

//         // console.log(status);
//         // if(status === 1)
//         // {
//         //     toast.success(" Nota creada correctamente ")
//         //     dispatch({type: CREATE_NOTES_SUCCESS})
//         //     dispatch(getNotes())
//         // }
//         // else if(status === 2)
//         // dispatch({type: LOGOUT})
//         // else {
//         //     toast.error(' Error al crear la nota. '+message)
//         //     // alert(' Error al crear la nota. '+message)
//         //         dispatch({type: CREATE_NOTES_ERROR})
//         // }
//         toast.error(' OK ')
//     } catch (error) {
//         toast.error(' Error al crear el código, vuelve a probarlo. ')        
//     }
// }
