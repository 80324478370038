import React, { useState } from 'react';

// import Jumbotron from 'react-bootstrap/Jumbotron';
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import {toast , Toaster } from 'react-hot-toast';

import { Input, Select as SelectAntd} from "antd";

import {enviaCorreo} from "../config/funciones";

import {  BASE_API } from "../config/constants";

// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,useDisclosure,
// } from '@chakra-ui/react'

import {Modal} from "antd"


const ExampleToast = ({ children }) => {
  const [show, toggleShow] = useState(false);

  return (
    <>
      {!show && <Button onClick={() => toggleShow(true)}>Show Toast</Button>}
      <Toast show={show} onClose={() => toggleShow(false)}>
        <Toast.Header>
          <strong className="mr-auto">React-Bootstrap</strong>
        </Toast.Header>
        <Toast.Body>{children}</Toast.Body>
      </Toast>
    </>
  );
};

const Inicio = () => {
    const [email, setEmail] = useState('potlanossoft@gmail.com');
    const [isAccepted, setIsAccepted] = useState(false);
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [confirmado, setConfirmado] = useState(false);    

    const [modalText, setModalText] = useState("Content of the modal");

    // const { isOpen, onOpen, onClose } = useDisclosure()

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            // console.log(' Por favor, introduce un correo electrónico válido. ');
            setError('Por favor, introduce un correo electrónico válido.');
            toast.error(" Por favor, introduce un correo electrónico válido. ")
            return;
        }
        if (!isAccepted) {
            // console.log(' Debes aceptar los términos y condiciones.');
            setError('Debes aceptar los términos y condiciones.');
            toast.error(" Debes aceptar los términos y condiciones.")
            return;
        }
        setError('');

        // crea el usuario si no existe, y si existe comprueba que esta confirmado

        // console.log(' Inicio Envio Correo ');
        
        const url = `${BASE_API}/insertausuario`;
        const data = {email:email,confirmado:0};

        const params = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body:JSON.stringify(data),
        };

        const response = await fetch(url, params);
        let result = await response.json();
        
        // console.log(" Respuesta insertausuario  => ", result.data);
        // console.log(" Respuesta Confirmado => ", result.data.confirmado);        

        if ( result.data.confirmado == 0){ // El correo no esta confirmado, se debe de confirmar para poder continuar.
          setConfirmado(false)
        }else{setConfirmado(true)}
          // toast.error(" Correo sin confirmar.")
        await EnviaCorreo();
        // }else{
        //    toast.success(" Correo confirmado.")
        // }
        
    };


    const EnviaCorreo = () => {  
      console.log(" Envia Correo ,",confirmado);
            
      if (confirmado){ 
        setModalText(" Se te va a enviar un correo para entrar en tu panel de control. ");      
      }else{ 
        setModalText(" Correo sin confirmar. Se te va a enviar un correo para confirmar. ");      
      }
      
      setOpen(true);
    };    

    const ConfirmacionOk = async () => {      
      setConfirmLoading(true);

      var tipocorreo = '';
      if (confirmado){ tipocorreo = 'confirmado' }else{ tipocorreo = 'confirmacion'}

      

      const response = await fetch(`${BASE_API}/send-email-confirmacion`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email:email,tipo:tipocorreo }),
      });

      if (response.ok) {
          alert('Correo enviado con éxito');
          setConfirmLoading(false);
          setOpen(false);
      } else {
          alert('Error al enviar el correo');
          setConfirmLoading(false);
          setOpen(false);
      }
    }

    const handleCancel = () => {
      // console.log("Clicked cancel button");
      setOpen(false);
    };

    return (
      <>
        <div style={{ margin: '20px', fontFamily: 'Arial, sans-serif' }}>            
            <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: 'auto' }}>
                <div style={{ marginBottom: '15px' }}>
                    <label>Introduce tu correo para empezar :</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                        style={{ width: '100%', padding: '8px' }}
                        required
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label>
                        <input
                            type="checkbox"
                            checked={isAccepted}
                            onChange={(e) => setIsAccepted(e.target.checked)}
                        />
                        Acepto los términos y condiciones
                    </label>
                </div>
                {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
                <button type="submit" className="btn btn-primary">
                    Enviar enlace para configurar tu tarjeta QR
                </button>
            </form>
        </div>
        <Modal
            title="Confirmación"
            open={open}
            onOk={ConfirmacionOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
          <p>{modalText}</p>
        </Modal>
        

        <Toaster/>
      </>
    );
};

export default Inicio;
