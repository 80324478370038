import {    
    Box,Tag,
    useDisclosure,

    InputGroup,InputLeftAddon,Stack,Input,Button as ButtonChakra ,
} from "@chakra-ui/react";

import React ,{useState, useEffect, useRef} from 'react'

import axios from 'axios';

import "lightgallery.js/dist/css/lightgallery.css";

import { LightgalleryProvider } from "react-lightgallery";



// import "./Codigos.scss";

import {Toaster } from 'react-hot-toast';

import VerTarjetaForm from "./Tarjeta"

import { useMediaQuery } from "@chakra-ui/react"

import { FloatButton  } from 'antd';
import { AddIcon } from "@chakra-ui/icons";

import {  BASE_API , BASE_URL } from "../config/constants";

import { creaCodigos, modificaCodigos} from "../controllers/codigos.js";

import { Table, Icon } from "semantic-ui-react";
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

// import InsertItem from './comunes/InsertaRegistro';
// import InsertButton from './comunes/InsertButton';
import { PlusOutlined } from '@ant-design/icons'

import { Space, QRCode,Checkbox, Form, Row, Col, Divider, Button, Modal,Typography, Flex, Input as Input_antd ,
  Image, Upload
} from 'antd';

export function Codigos(props) {

    const {usuario} = props;

    const [tempID,setId] = useState();  

    const [loading_antd, setLoading_antd] = useState(false);
    
    const [open_antdInsert, setOpen_antdInsert] = useState(false);
    const [open_antdEdit, setOpen_antdEdit] = useState(false);
       

    const [refetch, setRefetch] = useState(false);

    const onRefetch = () => setRefetch((prev) => !prev);

    const [data, setCodigos] = useState()
    const [escaneos, setEscaneos] = useState()

    const [overlay, setOverlay] = useState()

    const [modalTarjeta, setModalTarjeta] = useState(false);    

    const [descripcion, setDescripcion] = useState("")
    const [activo, setActivo] = useState("")

    // info personal
    const [nombre, setNombre] = useState("")
    const [apellidos, setApellidos] = useState("")
    const [telefono, setTelefono] = useState("")
    const [correoelectronico, setCorreoElectronico] = useState("")
    const [direccion, setDireccion] = useState("")
    const [poblacion, setPoblacion] = useState("")
    const [codigopostal, setCodigoPostal] = useState("")
    const [provincia, setProvincia] = useState("")
    const [pais, setPais] = useState("")
    const [sitioweb, setSitioWeb] = useState("")

    // info empresa
    const [empresa, setEmpresa] = useState("")
    const [puestoempresa, setPuestoEmpresa] = useState("")
    const [telefonoempresa, setTelefonoEmpresa] = useState("")
    const [correoelectronicoempresa, setCorreoElectronicoEmpresa] = useState("")
    const [direccionempresa, setDireccionEmpresa] = useState("")
    const [poblacionempresa, setPoblacionEmpresa] = useState("")
    const [codigopostalempresa, setCodigoPostalEmpresa] = useState("")
    const [provinciaempresa, setProvinciaEmpresa] = useState("")
    const [paisempresa, setPaisEmpresa] = useState("")
    const [sitiowebempresa, setSitioWebEmpresa] = useState("")

    const [foto, setFoto] = useState("")

    
    // para modificar
    const [descripcion2, setDescripcion2] = useState("")
    const [activo2, setActivo2] = useState("")
    
    // info personal
    const [nombre2, setNombre2] = useState("")
    const [apellidos2, setApellidos2] = useState("")
    const [telefono2, setTelefono2] = useState("")
    const [correoelectronico2, setCorreoElectronico2] = useState("")
    const [direccion2, setDireccion2] = useState("")
    const [poblacion2, setPoblacion2] = useState("")
    const [codigopostal2, setCodigoPostal2] = useState("")
    const [provincia2, setProvincia2] = useState("")
    const [pais2, setPais2] = useState("")
    const [sitioweb2, setSitioWeb2] = useState("")

    // info empresa
    const [empresa2, setEmpresa2] = useState("")
    const [puestoempresa2, setPuestoEmpresa2] = useState("")
    const [telefonoempresa2, setTelefonoEmpresa2] = useState("")
    const [correoelectronicoempresa2, setCorreoElectronicoEmpresa2] = useState("")
    const [direccionempresa2, setDireccionEmpresa2] = useState("")
    const [poblacionempresa2, setPoblacionEmpresa2] = useState("")
    const [codigopostalempresa2, setCodigoPostalEmpresa2] = useState("")
    const [provinciaempresa2, setProvinciaEmpresa2] = useState("")
    const [paisempresa2, setPaisEmpresa2] = useState("")
    const [sitiowebempresa2, setSitioWebEmpresa2] = useState("")

    const [foto2, setFoto2] = useState("")    

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    
    const [previewOpen2, setPreviewOpen2] = useState(false);    
    const [previewImage2, setPreviewImage2] = useState('');
    
    const { isOpen, onOpen, onClose } = useDisclosure()   

    const [pending, setPending] = useState(true);    
    
    const initialRef = useRef(null)
    const finalRef = useRef(null)


    const getBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);

        
    });
    const handlePreviewInsert = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);        
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);      
    };

    const handlePreviewEdit = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);        
      }
      setPreviewImage2(file.url || file.preview);
      setPreviewOpen2(true);
    };    

    const handleChangeInsert = ({ fileList: newFileList }) => setFileList(newFileList);
    const handleChangeEdit = ({ fileList: newFileList }) => setFileList2(newFileList);

    const uploadButton = (
      <button style={{ border: 0, background: 'none', }} type="button">
        <PlusOutlined />
        <div style={{ marginTop: 8,}}>
            Upload
        </div>
      </button>
    );    

    const [fileList, setFileList] = useState([]);
    const [fileList2, setFileList2] = useState([]);

    const showModalInsert = () => {
      setOpen_antdInsert(true);
    };

    const handleOkInsert = () => {
      setLoading_antd(true);
      setTimeout(() => {
        setLoading_antd(false);
        setOpen_antdInsert(false);
      }, 3000);
    };

    const handleCancelInsert = () => {
      setOpen_antdInsert(false);
    };

    const showModalEdit = () => {
      setOpen_antdEdit(true);
      setPreviewOpen2(true)
    };

    const handleOkEdit = () => {
      setLoading_antd(true);
      setTimeout(() => {
        setLoading_antd(false);
        setOpen_antdEdit(false);
      }, 3000);
    };

    const handleCancelEdit = () => {
      setOpen_antdEdit(false);
    };    


    const fotobase64apng = (foto) => {

      if (foto) {
        console.log(" useEffect foto2");
        // Convert base64 to a Blob and create an object URL
        const byteString = atob(foto.split(',')[1]); // Strip off the metadata part
        const mimeString = foto.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([uintArray], { type: mimeString });
        const objectUrl = URL.createObjectURL(blob);                

        setFileList2([
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: objectUrl,
          }])        

        // Cleanup object URL on component unmount
        return () => URL.revokeObjectURL(objectUrl);
    }


    }


    useEffect(()=>{       
        setPending(false);                 
    }, [data])  


    useEffect(()=>{       
      setFoto(fileList[0]?.thumbUrl);
      setFoto2(fileList2[0]?.thumbUrl);      
    }, [fileList,fileList2])      

    useEffect(() => {        
        
        async function fetchData() {
          const u = await getCodigos();
          setCodigos(u);                     
        }
        
        fetchData();

        async function fetchDataEscaneos() {
          const uescaneos = await getEscaneos();
          setEscaneos(uescaneos);                     
        }
        
        fetchDataEscaneos();        
        
      }, [refetch]); // Or [] if effect doesn't need props or state    



    const creaCodigo = async()=>{
        // alert('Crea Codigo '+descripcion)        
        await creaCodigos({
                      usuario:usuario.uuid,descripcion,activo:true,
                      nombre,apellidos,telefono,correoelectronico,direccion,poblacion,codigopostal,provincia,pais,sitioweb,                                        
                      empresa,puestoempresa,telefonoempresa,correoelectronicoempresa,direccionempresa,poblacionempresa,codigopostalempresa,provinciaempresa,paisempresa,sitiowebempresa,                       
                      foto:fileList[0]?.thumbUrl
                    })
        setOpen_antdInsert(false);
        onRefetch()
    }

    const modificaCodigo = async()=>{            
      const resultado = await modificaCodigos(tempID,{descripcion:descripcion2,activo:activo2,
                              nombre:nombre2,apellidos:apellidos2,
                              telefono:telefono2,correoelectronico:correoelectronico2,
                              direccion:direccion2,poblacion:poblacion2,codigopostal:codigopostal2,
                              provincia:provincia2,pais:pais2,sitioweb:sitioweb2,                                        
                              empresa:empresa2,puestoempresa:puestoempresa2,telefonoempresa:telefonoempresa2,
                              correoelectronicoempresa:correoelectronicoempresa2,direccionempresa:direccionempresa2,
                              poblacionempresa:poblacionempresa2,codigopostalempresa:codigopostalempresa2,
                              provinciaempresa:provinciaempresa2,paisempresa:paisempresa2,sitiowebempresa:sitiowebempresa2, 
                  foto:fileList2[0]?.thumbUrl})

      console.log(" resultado modifica => ",resultado);
      
      // creaCodigos({
      //               usuario:usuario.uuid,descripcion,activo:true,
      //               nombre,apellidos,telefono,correoelectronico,direccion,poblacion,codigopostal,provincia,pais,sitioweb,                                        
      //               empresa,puestoempresa,telefonoempresa,correoelectronicoempresa,direccionempresa,poblacionempresa,codigopostalempresa,provinciaempresa,paisempresa,sitiowebempresa, 
      //               foto
      //             })
      setOpen_antdEdit(false);
      onRefetch()
  }


    const getCodigos = async()=>{
        try {            
            console.log(" BASE_API => ",BASE_API)
            const response = await fetch(`${BASE_API}/getcodigos`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },                
            });

            const result = await response.json();

            return result.codigos;            
        
        } catch (e) {
            console.error("ERROR_cargaCodigos: " + e);
        }          
    }

    const getEscaneos = async()=>{
      try {            
          console.log(" BASE_API => ",BASE_API)
          const response = await fetch(`${BASE_API}/cuantos/todos`, {
              method: 'GET',
              headers: { 'Content-Type': 'application/json' },                
          });

          const result = await response.json();
          
          console.log(" Escaneos => ",result.estad);
          

          return result.estad[0];            
      
      } catch (e) {
          console.error("ERROR_cargaEscaneos: " + e);
      }          
  }    

  
    const limpiaCampos = async () => {
      setDescripcion('')
      setActivo(1)
      setNombre('')
      setApellidos('')
      setTelefono('')
      setCorreoElectronico('')
      setDireccion('')
      setPoblacion('')
      setCodigoPostal('')
      setProvincia('')
      setPais('')
      setSitioWeb('')

      setEmpresa('')
      setPuestoEmpresa('')
      setTelefonoEmpresa('')
      setCorreoElectronicoEmpresa('')
      setDireccionEmpresa('')
      setPoblacionEmpresa('')
      setCodigoPostalEmpresa('')
      setProvinciaEmpresa('')
      setPaisEmpresa('')
      setSitioWebEmpresa('')      


      setFoto('')
      setPreviewImage([])

    };
    
    const [isMobile] = useMediaQuery("(max-width: 768px)")    
    
    function doDownload(url, fileName) {
      const a = document.createElement('a');
      a.download = fileName;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

    const downloadCanvasQRCode = (id) => {
      //alert(id)
      const canvas = document.getElementById(id)?.querySelector('canvas');
      if (canvas) {
        
        const url = canvas.toDataURL();
        doDownload(url, 'QRCode.png');
      }
    };


    const columns = [
        {
          // name: "Acciones",
          name: <div style={styles.cabeceras}>Acciones</div>,
          width:"100px",
          selector: (row) => {
            return (
              <Actions                
                
                row={row}
                id={row.id}
                fotobase64apng={fotobase64apng}
                setId={setId}
                setOpen_antdEdit={setOpen_antdEdit}
                setDescripcion2={setDescripcion2}
                setActivo2={setActivo2}
                
                // info personal
                setNombre2={setNombre2}
                setApellidos2={setApellidos2}
                setTelefono2={setTelefono2}
                setCorreoElectronico2={setCorreoElectronico2}
                setDireccion2={setDireccion2}
                setPoblacion2={setPoblacion2}
                setCodigoPostal2={setCodigoPostal2}
                setProvincia2={setProvincia2}
                setPais2={setPais2}
                setSitioWeb2={setSitioWeb2}
            
                // info empresa
                setEmpresa2={setEmpresa2}
                setPuestoEmpresa2={setPuestoEmpresa2}                
                setTelefonoEmpresa2={setTelefonoEmpresa2}
                setCorreoElectronicoEmpresa2={setCorreoElectronicoEmpresa2}
                setDireccionEmpresa2={setDireccionEmpresa2}
                setPoblacionEmpresa2={setPoblacionEmpresa2}
                setCodigoPostalEmpresa2={setCodigoPostalEmpresa2}
                setProvinciaEmpresa2={setProvinciaEmpresa2}
                setPaisEmpresa2={setPaisEmpresa2}
                setSitioWebEmpresa2={setSitioWebEmpresa2}
                setPreviewImage2={setPreviewImage2}                
                setFoto2={setFoto2}
                
              />
            );
          },
        },      
        {name: <div style={styles.cabeceras}>Descripcion</div>, selector: row => { return ( <Columna valor={row.descripcion} />);},sortable: true,width:"200px"},      
        
        {name: <div style={styles.cabeceras}>Nombre</div>, selector: row => {return ( 
            <>
           <div class="ui card"> 
              <div class="content">
                <div class="header">{row.nombre}</div>               
                <div class="description">
                 <a href={row.qr} target="_blank" rel="noopener noreferrer"> Ver Tarjeta </a>
                 {/* <Button key="back" onClick={()=>{<VerTarjeta codigouuid={row.uuid}/>}} >
                     <Tarjeta codigouuid={row.uuid}/> 
                  </Button>*/},     
                </div>
              </div>
            </div>
            </>
          )},
          
          sortable: true,width:"250px",
            
        //   cell: (row) => creaHint(row, row.estado),
        },
        {name: <div style={styles.cabeceras}>Activo</div>, /*selector: row => row.estado,*/sortable: true,center:true,width:"120px",
            // cell: (row) => tagColor(row, row.activo),
            selector: (row) => { return ( <ViewChecks valor={row.activo} />);},
          },       
        
        {name: <div style={styles.cabeceras}>Escaneos Total</div>, /*selector: row => row.estado,*/sortable: true,center:true,       
          selector: (row) => { return ( <Columna valor={row.escaneos} />);},
        },               

        {name: <div style={styles.cabeceras}>QR</div>, selector: (row) => {return ( 
              <>
              <Space id="qr{row.id}" direction="vertical">
                <QRCode size={100} iconSize={100 / 4} type='canvas'
                    value={row.qr || '-'} 
                />
                {/* <Button type="primary"  onClick={downloadCanvasQRCode("qr"+row.id)}>
                    Descargar
                </Button> */}
              </Space>
              </>
            )} },        
        // {name: 'Teléfono', selector: row => row.telefono,sortable: true,width:"100px"},          
        // {name: 'Notas', selector: row => row.body,sortable: true,width:"250px",wrap: true},      
        // {name: 'Creado', selector: row => row.createdAt,sortable: true,width:"100px",
        //     cell : (row) => campoFecha(row.createdAt)
        // },      
        // {name: 'Modificado', selector: row => row.updatedAt,sortable: true,width:"100px",
        //   cell : (row) => campoFecha(row.updatedAt)
        // },                    
        // {
        //   name: "Pagado",width:"75px",
        //   selector: (row) => {
        //     return (
        //       <ViewChecks
        //         valor={row.pagado}
        //       />
        //     );
        //   },
        // },
        // {name: 'Estado', /*selector: row => row.estado,*/sortable: true,center:true,width:"120px",
        //   cell: (row) => tagColor(row, row.estado),
        // },                  
      ];
  
      //fechasubida: moment(body2.result[i].creation).format("DD/MM/YYYY h:mm"),
  
      const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
      };  
      
      const tableData = {
        columns,
        data
      };

      const onFinish = (values) => {
        console.log('Success:', values);
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };      
  
      

    return (
        <>
         <h1 style={styles.saludo}>¡Hola, {usuario.uuid} !</h1>

            <Modal open={modalTarjeta}
              footer={[                
                <Button key="back" onClick={()=>setModalTarjeta(false)} >Salir</Button>,          
              ]}
            />            

            <FloatButton 
                icon={<AddIcon fontSize={20} />}
                type="primary"
                onClick={() => {
                        // setOverlay(<OverlayTwo />)
                        showModalInsert()
                        limpiaCampos()
                        // onOpen()
                    }} />

            <Box mt={20} padding={18} >  

            <div class="ui cards">
              <div class="card">
                <div class="content">
                  <div class="header" style={styles.mensaje}>{escaneos?.total}</div>
                  {/* <div class="meta">Escaneos en total</div> */}
                  <div class="description">Escaneos en total</div>
                </div>
              </div>

            </div>

              {/* <InsertaCodigo
                open_antd={open_antd}
                handleOk={handleOk}
                handleCancel={handleCancel}
                loading_antd={loading_antd}
                creaCodigo={creaCodigo}

              /> */}

              {/* Modal Insert   */}
              <Modal
                  open={open_antdInsert}
                  title=""
                  onOk={handleOkInsert}
                  onCancel={handleCancelInsert}
                  width={1000}
                  footer={[
                            <Button key="submit" type="primary" htmlType="submit" loading={loading_antd} onClick={creaCodigo}>Crear</Button>,
                            <Button key="back" onClick={handleCancelInsert} >Salir</Button>,          
                          ]}
              >

                <Flex vertical gap={16} style={styles.estiloRow}>
                

                  <Row >
                    <Col span={18}>
                      <div>       {/* Descripcion */}
                        <InputGroup >
                          <InputLeftAddon width={150}>Descripción</InputLeftAddon>
                          <Input_antd width={100} value={descripcion} placeholder="Introduce la descripción" onChange={(e)=>setDescripcion(e.target.value)}></Input_antd>
                        </InputGroup>
                      </div>
                      <div>       {/* Activo */}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Activo</InputLeftAddon>
                          {/* <Input_antd value={activo}m onChange={(e)=>setActivo(e.target.value)}></Input_antd>                   */}
                          <Checkbox value={activo} onChange={(e)=>setActivo(e.target.checked)}></Checkbox>               
                        </InputGroup>                  
                      </div>
                    </Col>
                    <Col span={6}>
                    <Upload
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-circle"
                      fileList={fileList}
                      onPreview={handlePreviewInsert}
                      onChange={handleChangeInsert}
                    >
                        {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                        {previewImage && ( <Image
                            wrapperStyle={{
                              display: 'none',
                            }}
                            preview={{
                              visible: previewOpen,
                              onVisibleChange: (visible) => setPreviewOpen(visible),
                              afterOpenChange: (visible) => !visible && setPreviewImage(''),
                            }}
                            src={previewImage}
                          />
                        )}
                    </Col>

                  </Row>  
                  
                  <Row>
                    <Col span={10}>
                      <Divider style={{ borderColor: '#7cb305', }}> Información Personal </Divider>
                        <div>       {/* nombre */}
                          <InputGroup>
                            <InputLeftAddon width={150}>Nombre</InputLeftAddon>
                            <Input_antd value={nombre}m placeholder="Introduce el nombre" onChange={(e)=>setNombre(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>
                        <div>       {/* apellidos */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Apellidos</InputLeftAddon>
                            <Input_antd value={apellidos}m placeholder="Introduce los apellidos" onChange={(e)=>setApellidos(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                  
                        <div>       {/* telefono */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Telefono</InputLeftAddon>
                            <Input_antd value={telefono}m placeholder="Introduce el teléfono" onChange={(e)=>setTelefono(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                  
                        <div>       {/* correoelectronico */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Correo Electrónico</InputLeftAddon>
                            <Input_antd value={correoelectronico}m placeholder="Introduce el correo electrónico" onChange={(e)=>setCorreoElectronico(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                  
                        <div>       {/* direccion */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Dirección</InputLeftAddon>
                            <Input_antd value={direccion}m placeholder="Introduce la dirección" onChange={(e)=>setDireccion(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                                    
                        <div>       {/* Poblacion */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Población</InputLeftAddon>
                            <Input_antd value={poblacion}m placeholder="Introduce la población" onChange={(e)=>setPoblacion(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                                                      
                        <div>       {/* Código Postal */}
                          <InputGroup style={styles.estiloInputGroup}> 
                            <InputLeftAddon width={150}>Código Postal</InputLeftAddon>
                            <Input_antd value={codigopostal}m placeholder="Introduce el código postal" onChange={(e)=>setCodigoPostal(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                                                                        
                        <div>       {/* Provincia */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Provincia</InputLeftAddon>
                            <Input_antd value={provincia}m placeholder="Introduce la provincia" onChange={(e)=>setProvincia(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                                                      
                        <div>       {/* Pais */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>País</InputLeftAddon>
                            <Input_antd value={pais}m placeholder="Introduce el país" onChange={(e)=>setPais(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                                                                                          
                        <div>       {/* sitio web */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Sitio Web</InputLeftAddon>
                            <Input_antd value={sitioweb}m placeholder="Introduce el sitio web" onChange={(e)=>setSitioWeb(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                                                                                                                  
                    </Col>

                    <Col span={2}>
                    </Col>

                    <Col span={10}>

                      <Divider style={{ borderColor: '#7cb305', }}> Información Profesional </Divider>

                      <div>       {/* empresa */}
                        <InputGroup>
                          <InputLeftAddon width={150}>Empresa</InputLeftAddon>
                          <Input_antd value={empresa}m placeholder="Introduce la empresa" onChange={(e)=>setEmpresa(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                                    
                      <div>       {/* puesto empresa */}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Puesto en la empresa</InputLeftAddon>
                          <Input_antd value={puestoempresa}m placeholder="Introduce el puesto en la empresa" onChange={(e)=>setPuestoEmpresa(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                                                      
                      <div>       {/* telefono empresa */}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Telefono</InputLeftAddon>
                          <Input_antd value={telefonoempresa}m placeholder="Introduce el teléfono" onChange={(e)=>setTelefonoEmpresa(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                  
                      <div>       {/* correoelectronico empresa*/}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Correo Electrónico</InputLeftAddon>
                          <Input_antd value={correoelectronicoempresa}m placeholder="Introduce el correo electrónico" onChange={(e)=>setCorreoElectronicoEmpresa(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                  
                      <div>       {/* direccion empresa*/}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Dirección</InputLeftAddon>
                          <Input_antd value={direccionempresa}m placeholder="Introduce la dirección" onChange={(e)=>setDireccionEmpresa(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                                    
                      <div>       {/* Poblacion empresa*/}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Población</InputLeftAddon>
                          <Input_antd value={poblacionempresa}m placeholder="Introduce la población" onChange={(e)=>setPoblacionEmpresa(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                                                      
                      <div>       {/* Código Postal */}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Código Postal</InputLeftAddon>
                          <Input_antd value={codigopostalempresa} placeholder="Introduce el código postal" onChange={(e)=>setCodigoPostalEmpresa(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                                                                        
                      <div>       {/* Provincia */}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Provincia</InputLeftAddon>
                          <Input_antd value={provinciaempresa}m placeholder="Introduce la provincia" onChange={(e)=>setProvinciaEmpresa(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                                                      
                      <div>       {/* Pais */}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>País</InputLeftAddon>
                          <Input_antd value={paisempresa}m placeholder="Introduce el país" onChange={(e)=>setPaisEmpresa(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>        
                      <div>       {/* sitio web empresa*/}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Sitio Web</InputLeftAddon>
                            <Input_antd value={sitiowebempresa}m placeholder="Introduce el sitio web" onChange={(e)=>setSitioWebEmpresa(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                                                                                                                                                                                                                                                
                    </Col>
                  </Row>
                </Flex>
              </Modal>



              {/* Modal Edit   */}
              <Modal
                  open={open_antdEdit}
                  title=""
                  onOk={handleOkEdit}
                  onCancel={handleCancelEdit}
                  width={1000}
                  footer={[
                            <Button key="submit" type="primary" htmlType="submit" loading={loading_antd} onClick={modificaCodigo}>Guardar</Button>,
                            <Button key="back" onClick={handleCancelEdit} >Salir</Button>,          
                          ]}
              >
                <Flex vertical gap={16} style={styles.estiloRow}>
                  <Row>


                    
                    <Col span={18}>
                      <div>       {/* Descripcion */}
                        <InputGroup >
                          <InputLeftAddon width={150}>Descripción</InputLeftAddon>
                          <Input_antd width={100} value={descripcion2} placeholder="Introduce la descripción" onChange={(e)=>setDescripcion2(e.target.value)}></Input_antd>
                        </InputGroup>
                      </div>
                      <div>       {/* Activo */}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Activo</InputLeftAddon>
                          {/* <Input_antd value={activo2}m onChange={(e)=>setActivo2(e.target.value)}></Input_antd>    */}
                          <Checkbox value={activo2} onChange={(e)=>setActivo2(e.target.checked)}></Checkbox>               
                        </InputGroup>                  
                      </div>
                    </Col>
                    <Col span={6}>                                           
                      <Upload
                        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                        listType="picture-circle"
                        fileList={fileList2}
                        onPreview={handlePreviewEdit}
                        onChange={handleChangeEdit}
                      >
                          {fileList2.length >= 1 ? null : uploadButton}
                      </Upload>
                          {previewImage2 && ( <Image
                              wrapperStyle={{
                                display: 'none',
                              }}
                              preview={{
                                visible: previewOpen2,
                                onVisibleChange: (visible) => setPreviewOpen2(visible),
                                afterOpenChange: (visible) => !visible && setPreviewImage2(''),
                              }}
                              src={previewImage2}
                            />
                          )}
                    </Col>                    
                  </Row>  
                  
                  <Row>
                    <Col span={10}>
                      <Divider style={{ borderColor: '#7cb305', }}> Información Personal </Divider>
                        <div>       {/* nombre */}                          
                          <InputGroup>
                            <InputLeftAddon width={150}>Nombre</InputLeftAddon>
                            <Input_antd value={nombre2} placeholder="Introduce el nombre" onChange={(e)=>setNombre2(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>
                        <div>       {/* apellidos */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Apellidos</InputLeftAddon>
                            <Input_antd value={apellidos2} placeholder="Introduce los apellidos" onChange={(e)=>setApellidos2(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                  
                        <div>       {/* telefono */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Telefono</InputLeftAddon>
                            <Input_antd value={telefono2} placeholder="Introduce el teléfono" onChange={(e)=>setTelefono2(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                  
                        <div>       {/* correoelectronico */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Correo Electrónico</InputLeftAddon>
                            <Input_antd value={correoelectronico2} placeholder="Introduce el correo electrónico" onChange={(e)=>setCorreoElectronico2(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                  
                        <div>       {/* direccion */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Dirección</InputLeftAddon>
                            <Input_antd value={direccion2} placeholder="Introduce la dirección" onChange={(e)=>setDireccion2(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                                    
                        <div>       {/* Poblacion */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Población</InputLeftAddon>
                            <Input_antd value={poblacion2} placeholder="Introduce la población" onChange={(e)=>setPoblacion2(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                                                      
                        <div>       {/* Código Postal */}
                          <InputGroup style={styles.estiloInputGroup}> 
                            <InputLeftAddon width={150}>Código Postal</InputLeftAddon>
                            <Input_antd value={codigopostal2} placeholder="Introduce el código postal" onChange={(e)=>setCodigoPostal2(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                                                                        
                        <div>       {/* Provincia */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Provincia</InputLeftAddon>
                            <Input_antd value={provincia2} placeholder="Introduce la provincia" onChange={(e)=>setProvincia2(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                                                      
                        <div>       {/* Pais */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>País</InputLeftAddon>
                            <Input_antd value={pais2} placeholder="Introduce el país" onChange={(e)=>setPais2(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                                                                                          
                        <div>       {/* sitio web */}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Sitio Web</InputLeftAddon>
                            <Input_antd value={sitioweb2} placeholder="Introduce el sitio web" onChange={(e)=>setSitioWeb2(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                                                                                                                  
                    </Col>

                    <Col span={2}>
                    </Col>

                    <Col span={10}>

                      <Divider style={{ borderColor: '#7cb305', }}> Información Profesional </Divider>

                      <div>       {/* empresa */}                      
                        <InputGroup>
                          <InputLeftAddon width={150}>Empresa</InputLeftAddon>
                          <Input_antd value={empresa2} placeholder="Introduce la empresa" onChange={(e)=>setEmpresa2(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                                    
                      <div>       {/* puesto empresa */}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Puesto en la empresa</InputLeftAddon>
                          <Input_antd value={puestoempresa2} placeholder="Introduce el puesto en la empresa" onChange={(e)=>setPuestoEmpresa2(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                                                      
                      <div>       {/* telefono empresa */}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Telefono</InputLeftAddon>
                          <Input_antd value={telefonoempresa2} placeholder="Introduce el teléfono" onChange={(e)=>setTelefonoEmpresa2(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                  
                      <div>       {/* correoelectronico empresa*/}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Correo Electrónico</InputLeftAddon>
                          <Input_antd value={correoelectronicoempresa2} placeholder="Introduce el correo electrónico" onChange={(e)=>setCorreoElectronicoEmpresa2(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                  
                      <div>       {/* direccion empresa*/}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Dirección</InputLeftAddon>
                          <Input_antd value={direccionempresa2} placeholder="Introduce la dirección" onChange={(e)=>setDireccionEmpresa2(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                                    
                      <div>       {/* Poblacion empresa*/}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Población</InputLeftAddon>
                          <Input_antd value={poblacionempresa2} placeholder="Introduce la población" onChange={(e)=>setPoblacionEmpresa2(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                                                      
                      <div>       {/* Código Postal */}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Código Postal</InputLeftAddon>
                          <Input_antd value={codigopostalempresa2} placeholder="Introduce el código postal" onChange={(e)=>setCodigoPostalEmpresa2(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                                                                        
                      <div>       {/* Provincia */}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>Provincia</InputLeftAddon>
                          <Input_antd value={provinciaempresa2} placeholder="Introduce la provincia" onChange={(e)=>setProvinciaEmpresa2(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>                                                      
                      <div>       {/* Pais */}
                        <InputGroup style={styles.estiloInputGroup}>
                          <InputLeftAddon width={150}>País</InputLeftAddon>
                          <Input_antd value={paisempresa2} placeholder="Introduce el país" onChange={(e)=>setPaisEmpresa2(e.target.value)}></Input_antd>                  
                        </InputGroup>                  
                      </div>        
                      <div>       {/* sitio web empresa*/}
                          <InputGroup style={styles.estiloInputGroup}>
                            <InputLeftAddon width={150}>Sitio Web</InputLeftAddon>
                            <Input_antd value={sitiowebempresa2}m placeholder="Introduce el sitio web" onChange={(e)=>setSitioWebEmpresa2(e.target.value)}></Input_antd>                  
                          </InputGroup>                  
                        </div>                                                                                                                                                                                                                                                
                    </Col>
                  </Row>
                </Flex>                
              </Modal>

              <DataTableExtensions {...tableData}
                print={false}
                export={false}>
                <DataTable
                  dense  
                  striped
                  progressPending={pending}
                  // title="Lista de Códigos"
                  columns={columns}
                  data={data}
                  pagination
                  highlightOnHover
                  paginationComponentOptions={paginationComponentOptions}
                  paginationRowsPerPageOptions={[100,200,300,400,500]}
                  paginationPerPage={100}                  
                  
                />
              </DataTableExtensions>

              <Toaster/>

            </Box>
        </>
    )
}

function Actions(props) {
    const { row, setOpen_antdEdit,setId,
          id, setDescripcion2 , setActivo2 , setNombre2 , setApellidos2 , setTelefono2 , 
          setCorreoElectronico2 , setDireccion2 , setPoblacion2 , setCodigoPostal2 , 
          setProvincia2 , setPais2 , setSitioWeb2 , 
          setEmpresa2 ,  setPuestoEmpresa2 , setTelefonoEmpresa2 , 
          setCorreoElectronicoEmpresa2 , setDireccionEmpresa2 , setPoblacionEmpresa2 , setCodigoPostalEmpresa2 , 
          setProvinciaEmpresa2 , setPaisEmpresa2 , setSitioWebEmpresa2 , setFoto2   , setPreviewImage2 ,
          fotobase64apng
        } = props;
  
    // if (row.foto) { setPreviewOpen2(true)}    

    return (
      <Table.Cell textAlign="right">
          <Icon name="pencil" onClick={() => {

              fotobase64apng(row.foto)

              setOpen_antdEdit(true)

              setId(id);
              setDescripcion2(row.descripcion)
              setActivo2(row.activo)
              setNombre2(row.nombre)
              setApellidos2(row.apellidos)
              setTelefono2(row.telefono)
              setCorreoElectronico2(row.correoelectronico)
              setDireccion2(row.direccion)
              setPoblacion2(row.poblacion)
              setCodigoPostal2(row.codigopostal)
              setProvincia2(row.provincia)
              setPais2(row.pais)
              setSitioWeb2(row.sitioweb)

              // info empresa
              setEmpresa2(row.empresa)
              setPuestoEmpresa2(row.puestoempresa)              
              setTelefonoEmpresa2(row.telefonoempresa)
              setCorreoElectronicoEmpresa2(row.correoelectronicoempresa)
              setDireccionEmpresa2(row.direccionempresa)
              setPoblacionEmpresa2(row.poblacionempresa)
              setCodigoPostalEmpresa2(row.codigopostalempresa)
              setProvinciaEmpresa2(row.provinciaempresa)
              setPaisEmpresa2(row.paisempresa)
              setSitioWebEmpresa2(row.sitiowebempresa)

              setFoto2(row.foto)
              setPreviewImage2(row.foto)
              //modalEdit.onOpen();
            }}/> 
        
      </Table.Cell>
    );
  }


function tagColor(row, data) {
  
    if (!data) {
      return "";
    } else {
      var color = "";
      var colortexto = "";
      // var tag = "";  
  
      var valorcampo = '';

      colortexto = "black";
  
      if (row.activo) {  
            color = "green";
            valorcampo = 'Activo';
      } else if (row.activo) {            
            color = "red";
            valorcampo = 'No Activo';
      }
    //   if (row.estado.toUpperCase() === "finalizado".toUpperCase()) {
    //     color = "green";
    //     // tag = data;
    //   } else if (row.estado.toUpperCase() === "pendiente".toUpperCase()) {
    //     color = "red";
    //     // tag = data;
    //   } else if (row.estado.toUpperCase() === "avisado".toUpperCase()) {
    //     color = "blue";
    //     colortexto = "white";
    //     // tag = data;
    //   } else if (row.estado.toUpperCase() === "cancelado cliente".toUpperCase()) {
    //     color = "grey";
    //     // tag = data;
    //   } else if (row.estado.toUpperCase() === "ALBARÁN".toUpperCase()) {
    //     color = "blue";
    //     // tag = data;
    //   } else {
    //     color = "grey";
    //     // tag = data;
    //   }
  
    //   var valorcampo = data;
  
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            alignContent: "center",
            cursor: "pointer",
            textAlign: "center",
          }}
          data-tag="allowRowEvents"        
        >
            <Tag color={colortexto} bgColor={color} > {valorcampo}</Tag>
  
  
        </div>
      );
    }
  }
  
function ViewChecks(props) {
    const { valor } = props;
  
    return (
      // <Table.Cell className="status">
      //   {valor ? <Icon name="check" /> : <Icon name="close" />}      
      // </Table.Cell>
      <>
      <div class="ui toggle checkbox read-only">
        <input type="checkbox" name="public" checked={valor}></input>
        <label></label>
      </div>
      </>
    );
}  

function Columna(props) {
  const { valor } = props;

  return (
    <>
    <div class="ui card">
       <div class="center aligned content">
         <div class="header">{valor}</div>               
         {/* <div class="description">
          <a href={row.qr} target="_blank" rel="noopener noreferrer"> Ver Tarjeta </a>
         </div> */}
       </div>
     </div>
     </>
  );
}  


// Estilos en línea
const styles = {
  container: {
      textAlign: 'center',
      marginTop: '50px',
      fontFamily: 'Arial, sans-serif',
  },

  saludo: {
      color: '#4CAF50',
  },

  mensaje: {
      fontSize: '28px',
      color: '#555',
  },

  cabeceras: {
    fontSize: '15px',
    color: '#555',
},

  boton: {
      padding: '10px 20px',
      fontSize: '16px',
      color: '#fff',
      backgroundColor: '#4CAF50',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
  },

  estiloInputGroup : {
      marginTop: '0.2em'
  },
  estiloRow : {
    marginTop: '2em'
}  
};  