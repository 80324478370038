import logo from './logo.svg';
import './App.css';

import { Routes, Route , useState } from "react-router-dom"
import Inicio from "./components/Inicio"
import SobreNosotros from "./components/SobreNosotros"
import VerTarjetaForm from "./components/Tarjeta"
import Configura from "./components/Configura.js"
import Confirma from "./components/Confirma.js"
import Panel from "./components/Panel.js"

import { useLocation } from 'react-router-dom';

// some Root.js file


function App() {

  const location = useLocation(); // Hook para obtener la ruta actual
      
  return (
      <>
      {!location.pathname.includes('/panel') && !location.pathname.includes('/tarjeta')?
        <>
          <div className="Aplicacion" style={styles.container}>        
              <header style={styles.encabezado}>
                <h1>qr-app</h1>
                <nav>
                  <a href="#" style={styles.enlace}>Inicio</a>
                  <a href="panel/ff374a9d-6aef-4596-b404-a193817aaf5c" style={styles.enlace}>Panel de Control</a>
                  <a href="#" style={styles.enlace}>Contacto</a>
                </nav>
              </header>
          </div>
        </> : '' }  
      <Routes>
        
        <Route exact path="/tarjeta/:uuid" element={ <VerTarjetaForm /> } />
        <Route exact path="/panel/:uuid" element={ <Panel /> } />
        <Route exact path="/confirma/:uuid" element={ <Confirma /> } />
        <Route exact path="/sobrenosotros" element={ <SobreNosotros /> } />

        <Route exact path="/" element={ <Inicio /> } />        

      </Routes>

      
      
    </>
  );
}


// Estilos en línea
const styles = {
  container: {
      textAlign: 'center',
      marginTop: '50px',
      fontFamily: 'Arial, sans-serif',
  },
  encabezado: {
      backgroundColor: '#4CAF50',
      color: '#fff',
      padding: '10px 0',
      marginBottom: '20px',
  },
  enlace: {
      color: '#fff',
      textDecoration: 'none',
      margin: '0 10px',
      fontSize: '16px',
  },
  saludo: {
      color: '#4CAF50',
  },
  mensaje: {
      fontSize: '18px',
      color: '#555',
  },
  boton: {
      padding: '10px 20px',
      fontSize: '16px',
      color: '#fff',
      backgroundColor: '#4CAF50',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
  },
};

export default App;

  

