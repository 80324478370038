import React ,{useState, useEffect} from 'react'
import { useParams } from "react-router-dom";

import axios from 'axios';

import {  BASE_API } from "../config/constants";

import {Tag } from "antd";

function Confirma(props) {

    const [usuarios, setUsuarios] = useState()
    const [estaConfirmada, setEstaConfirmada] = useState();
    const [id, setid] = useState()

    const uuid = useParams();

    useEffect(()=>{
        // console.log(" Paso 1 ");
        
        setid(uuid);        

        // console.log(" Paso 2 ");
    }, [])  

    useEffect(() => {
        async function fetchData() {
          const u = await buscarUsuario();

          setUsuarios(u);

          if (u.confirmado == 1){
                setEstaConfirmada(true)
          } else {
                setEstaConfirmada(false)            
          }
        }
        fetchData();
      }, [uuid]); // Or [] if effect doesn't need props or state

      


    const buscarUsuario = async()=>{
        try {
                           
            const response = await fetch(`${BASE_API}/getusuario/${uuid.uuid}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },                
            });

            const result = await response.json();

            return result.data[0];
        
        } catch (e) {
            console.error("ERROR_buscarUsuario : " + e);
        }          
    }

    const handleConfirmar = async () => {
        
        const url = `${BASE_API}/confirmacuenta`;
        const data = {uuid:uuid.uuid};

        const params = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body:JSON.stringify(data),
        };

        const response = await fetch(url, params);
        let result = await response.json();  
        
        console.log( "confirma cuenta ",result);
        
    };

  

    return (
        <>
        <div style={styles.container}>
            <h1 style={styles.saludo}>¡Hola, {usuarios?.email}!</h1>

            {estaConfirmada ? 
                <>
                    <p style={styles.mensaje}>Tu cuenta ya esta confirmada. Haz click en el siguiente botón para entrar en tu cuenta.</p>
                        <button style={styles.boton} onClick={handleConfirmar}>
                                Haz click <a href="">aquí</a> para entrar en el panel de control
                        </button>
                </>
                :
                <>
                    <p style={styles.mensaje}>Por favor, confirma tu cuenta haciendo clic en el botón a continuación.</p>
                    <button style={styles.boton} onClick={handleConfirmar}>
                        Haz click aqui para confirmar la cuenta
                    </button>                
                </>            
        }


            
        </div>

        </>
    )
}


// Estilos en línea
const styles = {
    container: {
        textAlign: 'center',
        marginTop: '50px',
        fontFamily: 'Arial, sans-serif',
    },
    saludo: {
        color: '#4CAF50',
    },
    mensaje: {
        fontSize: '18px',
        color: '#555',
    },
    boton: {
        padding: '10px 20px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#4CAF50',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};


export default Confirma