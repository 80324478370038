import React, { useState } from 'react';

import { Codigos } from './Codigos.js'

import { useParams } from "react-router-dom";


const Panel = () => {
    const [paginaActual, setPaginaActual] = useState('codigos');
    
    const uuid = useParams();
    

    const renderizarPagina = () => {
        switch (paginaActual) {
            case 'codigos':
                return <Codigos usuario={uuid}/>;
            case 'listados':
                return <Listados />;
            case 'configuración':
                return <Configuracion />;
            default:
                return <div style={styles.bienvenida}>Selecciona una opción del menú lateral.</div>;
        }
    };

    return (
        <>
        <div style={styles.contenedorPrincipal}>
            <div style={styles.menuLateral}>
                <h2 style={styles.tituloMenu}>Menú</h2>
                <button style={styles.botonMenu} onClick={() => setPaginaActual('codigos')}>Códigos</button>
                <button style={styles.botonMenu} onClick={() => setPaginaActual('listados')}>Listados</button>
                <button style={styles.botonMenu} onClick={() => setPaginaActual('configuración')}>Configuración</button>
            </div>
            <div style={styles.contenido}>{renderizarPagina()}</div>
        </div>
      </>
    );
};

const Listados = () => <div style={styles.pagina}>Página de Listados</div>;
const Configuracion = () => <div style={styles.pagina}>Página de Configuración</div>;

const styles = {
    contenedorPrincipal: {
        display: 'flex',
        height: '100vh',
        fontFamily: 'Arial, sans-serif',
    },
    menuLateral: {
        width: '250px',
        backgroundColor: '#87CEFA',
        color: '#fff',
        padding: '20px',
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
    },
    tituloMenu: {
        fontSize: '24px',
        marginBottom: '20px',
    },
    botonMenu: {
        display: 'block',
        width: '100%',
        padding: '10px',
        fontSize: '16px',
        color: '#fff',
        backgroundColor: '#4682B4',
        border: 'none',
        borderRadius: '5px',
        marginBottom: '10px',
        cursor: 'pointer',
        textAlign: 'left',
    },
    contenido: {
        flex: 1,
        padding: '20px',
        backgroundColor: '#f9f9f9',
    },
    pagina: {
        // fontSize: '18px',
        // color: '#333',
    },
    bienvenida: {
        fontSize: '20px',
        color: '#555',
        textAlign: 'center',
        marginTop: '50px',
    },
};
export default Panel;

// Para usarlo, reemplaza el contenido en App.js con el siguiente ejemplo:
// import React from 'react';
// import ReactDOM from 'react-dom';
// import MenuLateral from './MenuLateral';
// ReactDOM.render(<MenuLateral />, document.getElementById('root'));
